<template>
    <div id="team" class="py-[80px] w-[100%] flex flex-col lg:flex-row bg-gradient-to-r from-neutral-700 to-primary-500 items-center">
        <div class="w-[100%] lg:w-[50%] 2xl:w-[64%] flex flex-col justify-center items-center ">
            <h2 class="text-gray-50 text-title-3  p-10 w-[100%] flex flex-col justify-center items-center">
                Notre équipe
            </h2>
            <p class="text-gray-50 text-body-3 text-justify m-2 w-[64%] lg:w-[50%]">
                Dans une collaboration issue d’une activité pédagogique, Grégoire, ingénieur passionné de technologie, et Grégory, professionnel du transport et de l'affrètement, ont uni leurs compétences et leurs visions pour créer TraQKING, un projet innovant.
            </p>
            <p class="text-gray-50 text-body-3 text-justify m-2 w-[64%] lg:w-[50%]">
                Grégoire excelle dans le développement logiciel, tandis que Grégory apporte une expertise du secteur du transport et stratégique en gestion d'entreprise.
            </p>
            <p class="text-gray-50 text-body-3 text-justify m-2 w-[64%] lg:w-[50%]">
                Leur collaboration dynamique et complémentaire a donné naissance à ce projet d'innovation prometteur.
            </p>
            <p class="text-gray-50 text-body-3 text-justify m-2 w-[64%] lg:w-[50%]">
                Avec détermination, ils aspirent à repousser les limites de l'industrie et à réaliser leur vision commune de transformer le paysage technologique et du transport routier de marchandises.
            </p>
        </div>
        <div class="w-[100%] lg:w-[50%] 2xl:w-[33%] flex flex-row justify-around p-[2rem] lg:border-l">
            <a href="https://www.linkedin.com/in/gregorymermet/"><div class="flex flex-col justify-center items-center">
                <img class="w-[120px] h-[120px] rounded-[120px] border-[5px] border-warning-500" alt="Gregory MERMET picture" src="../assets/gregory-mermet.jpeg"  width="150" height="150">
                <h3 class="mt-4 text-body-2 text-gray-50">Grégory MERMET</h3>
                <p class="mt-4 text-body-5 text-gray-50">Co-fondateur</p>
                <p class="mt-1 text-body-5 text-gray-50">Président</p>
            </div></a>
            <a href="https://www.linkedin.com/in/grégoire-di-ferro-861063180/">
            <div class="flex flex-col justify-center items-center">
                <img class="w-[120px] h-[120px] rounded-[120px] border-[5px] border-warning-500" alt="Grégoire DI FERRO picture" src="../assets/gregoire-di-ferro.jpeg" width="150" height="150">
                <h3 class="mt-4 text-body-2 text-gray-50">Grégoire DI FERRO</h3>
                <p class="mt-4 text-body-5 text-gray-50">Co-fondateur</p>
                <p class="mt-1 text-body-5 text-gray-50">Directeur Général</p>
            </div></a>
        </div>
    </div>
</template>
  
<script>
export default {
name: 'TeamVue',
}
</script>
  
  