<template>
  <div class="relative top-0 left-0 overflow-x-hidden font-lato w-[100%]" :key="reload">
    <HeaderVue/>
    <div class="flex flex-row">
      <div class="hidden lg:block w-[20vw] bg-warning-500"></div> 
      <div class="flex flex-col justify-between items-end lg:w-[80vw]">
        <ActivityVue/>
        <TeamVue/>
        <ReferenceVue/>
        <ContactVue/>
        <FooterVue/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderVue from './components/Header.vue'
import FooterVue from './components/Footer.vue'
import ActivityVue from './components/Activity.vue'
import TeamVue from './components/Team.vue'
import ReferenceVue from './components/Reference.vue'
import ContactVue from './components/Contact.vue'


export default {
  name: 'App',
  components: {
    HeaderVue,
    FooterVue,
    TeamVue,
    ActivityVue,
    ReferenceVue,
    ContactVue
  },
  data(){
        return {
            reload: 1,
        }
    },

  created(){
        this.getDeviceType()
        this.getOrientation()
        this.$nextTick(() => {
        window.addEventListener('resize', () => {
            var device = this.$device;
            this.getDeviceType()
            this.getOrientation()
            if(this.$device == device){
                this.reload++
            }
        })})
    },

    methods:{
        isMobile() {
            return this.$device === 'mobile' ||  this.$orientation === 'portrait'
        },
        getDeviceType() {
            var device = null;
            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                device = "tablet";
            } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                device = "mobile";
            } else {
                device = "desktop";
            }
            this.$global.$device = device
        },
        getOrientation(){
            this.$global.$orientation = window.innerWidth / window.innerHeight > 1 ? 'landscape' : 'portrait'
        }
    }
}
</script>

