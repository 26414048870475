import Updatable from './Updatable'
import Address from './Address'
import Traqr from './Traqr'
import Contact from './Contact'

import axios from 'axios'


export default class Destination {


    constructor(object = {}){
        this.build(object)
    }
 
    build(object){
        object.id ? this.id = object.id : this.id = null

        object.name ? this.name = object.name : this.name = null

        object.loadingConstraints ? this.loadingConstraints = object.loadingConstraints : this.loadingConstraints = []

        object.loadingConstraintsComment ? this.loadingConstraintsComment = object.loadingConstraintsComment : this.loadingConstraintsComment = null

        object.contact ? this.contact = new Contact(object.contact) : this.contact = null

        object.contactIri ? this.contactIri = object.contactIri : this.contactIri = null

        object.rawAddress ? this.rawAddress = object.rawAddress : this.rawAddress = null

        object.address ? this.address = new Address(object.address) : this.address = null
        
        if(object.consigned){
            var consigned = []
            object.consigned.forEach((traqr) => {
                consigned.push(new Traqr(traqr))
            })
            this.consigned = consigned
        }

        if(object.shipped){
            var shipped = []
            object.shipped.forEach((traqr) => {
                shipped.push(new Traqr(traqr))
            })
            this.shipped = shipped
        }

        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null

    }

    uri(){
        return process.env.VUE_APP_API_PREFIX+'/destinations/'+this.id
    }

    validate(){
        return this.address !== null && this.address.id !== null
    }

    toPostJson(){
        return {
            "name": this.name,
            "loadingConstraints": this.loadingConstraints,
            "loadingConstraintsComment": this.loadingConstraintsComment,
            "rawAddress": this.rawAddress,
            "address": this.address.uri(),
            "contact": this.contact ? this.contact.uri() : null,
          }
    }

    toPatchJson(){
        return {
            "name": this.name,
            "loadingConstraints": this.loadingConstraints,
            "loadingConstraintsComment": this.loadingConstraintsComment,
            "rawAddress": this.rawAddress,
            "address": this.address.uri(),
            "contact": this.contact ? this.contact.uri() : null,
          }
    }

    async update(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/destinations/'+this.id, this.toPatchJson(),
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
            withCredentials: true 
        })
        .then(response => this.build(response.data))
        return this
    }

    async save(){
        await axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/destinations', this.toPostJson(),
        {
            headers: {
                'Content-Type': 'application/ld+json',
            },
            withCredentials: true 
        })
        .then(response => this.build(response.data))
        return this
    }

    async deleteContact(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/destinations/'+this.id, 
        {
            "contact" : null
        },
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
            withCredentials: true 
        })
        .then(response => {
            if(response.status == 201){
                console.log(response)
            }
        })
    }

    async searchDestination(q){
        var destinations = []
        var query = process.env.VUE_APP_API_BASE_PATH+'/destinations?q='+q
        await axios
            .get(query, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    destinations = response.data['hydra:member']
                }
            })
        if(!destinations.length){
            var addresses = []
            query = process.env.VUE_APP_API_BASE_PATH+'/addresses_autocomplete?input='+q
            if(this.sessionToken){
                query = query +'&sessiontoken='+this.sessionToken
            }
            await axios
                .get(query, {  withCredentials: true })
                .then(response => {
                    if(response.status == 200){
                        this.sessionToken = response.data.sessiontoken
                        addresses = response.data.predictions

                    }
                })
            return addresses
        }
        destinations.forEach((destination,index) => {
            destinations[index] = new Destination(destination)
            destinations[index].description = destinations[index].contact ? destinations[index].contact.companyName +" - "+ destinations[index].address.display() : destinations[index].address.display()
        })
        return destinations
    }

    async setDestination(object) {
        if(object.address !== undefined){
            this.build(object)
            return this
        } else {
            var address = new Address();
            var query = process.env.VUE_APP_API_BASE_PATH+'/address_details?place_id='+object.place_id+'&sessiontoken='+this.sessionToken
            await axios
            .get(query, {  withCredentials: true })
            .then(async response => {
                if(response.status == 200){
                    address.build(response.data)
                    this.sessionToken = null
                    this.address = address
                    await this.save()
                }
            })
            return this
        }
    }


}