import { createSSRApp } from 'vue'
import App from './App.vue'
import './index.css'
import axios from 'axios'
import * as heroIcons  from "@heroicons/vue/24/outline"
import { styles } from '@/lib/styles'
import VueGtag from "vue-gtag";


const app = createSSRApp(App)

app.config.globalProperties.$me = null
app.config.globalProperties.$axios = axios
app.config.globalProperties.$heroIcons = heroIcons
app.config.globalProperties.$styles = styles
app.config.globalProperties.$global = app.config.globalProperties
app.config.globalProperties.$basePath = "https://api.traq-king.com"

app.use(VueGtag, {
    config: { id: "G-826MEQDCC5" }
    })

app.mount('#app')
