<template>
    <nav class="hidden lg:block w-[100vw] h-[100vh] overflow-hidden p-0 shadow-xl">
        <div class="flex w-[100%] h-auto min-w-[400px]">
            <div class="absolute top-0 right-0 h-[100vh] w-[100vw] bg-nav flex flex-row-reverse">
              <img src="../assets/truck-resized.png">  
            </div>
            <img class="absolute left-0 top-0 max-w-[200%] !h-[100vh] overflow-hidden" src="../assets/header-shapes.svg">
            <div id="fixed-nav" class="flex justify-between items-center" :class="{ 'onScroll': !view.topOfPage}">
              <a href="#" @click="$gtag.event('clickLogo', { method: 'Google' })"><div class="my-4 flex flex-row align-center" >
                    <img alt="TraQKING logo" src="../assets/logo.svg"  width="80">
                    <img alt="TraQKING text logo" src="../assets/name.svg" width="150">
                </div></a>
                <div>
                  <ul class="text-white text-body-3 ml-auto flex flex-row items-center">
                      <li class="mx-2 w-[120px] text-center" @click="$gtag.event('clickActivity', { method: 'Google' })"><a href="#activity">Notre Activité</a></li>
                      <li class="mx-2 w-[120px] text-center" @click="$gtag.event('clickTeam', { method: 'Google' })"><a href="#team">Notre équipe</a></li>
                      <li class="mx-2 w-[120px] text-center" @click="$gtag.event('clickContact', { method: 'Google' })"><a href="#contact">Nous contacter</a></li>
                  </ul>
                </div>
                <div class="flex flex-row">
                  <ButtonVue class="!mx-2"
                  :state="'warning'"
                  :height="'medium'"
                  :filling="'text'"
                  :label="'Se connecter'"
                  @click="() => {
                    window.location.replace('https://api.traq-king.com/login?dest=app.traq-king.com')
                    $gtag.event('clickLogin', { method: 'Google' })
                  }"
                  />
                  <ButtonVue class="!mx-2"
                  :state="'warning'"
                  :height="'medium'"
                  :filling="'filled'"
                  :label="'S\'inscrire'"
                  @click="() => {
                    window.location.replace('https://api.traq-king.com/register_user')
                    $gtag.event('clickRegister', { method: 'Google' })
                  }"
                  />
                </div>
            </div>
        </div>
        <div class="hidden lg:flex relative top-0 mt-[100px] left-[2%] h-[25%] flex-col justify-start items-start p-4">
          <h1 class="text-title-3 text-gray-300 mb-10 w-[33vw]">Apportez de la visibilité à votre supply chain au travers de TraQKING, un outil leger et ergonomique pour tous les acteurs de vos acheminements !</h1>
          <div class="triangle-main text-body-3 text-gray-50 mt-10">
            <div class="triangle-bottom"></div>
            <p class="mb-4">
              TraQKING propose un procédé simple en trois étapes permettant de récuperer l'information nécessaire pour une meilleure visibilité de votre Supply Chain. 
            </p>
            <p class="mb-4">
              Que vous soyez <b>chargeur</b>, <b>affréteur</b>, ou bien <b>transporteur</b>, vous pourrez centraliser toutes les informations de vos expéditions au sein d'un espace dédié qui deviendra le seul intermédiaire entre tous les acteurs de vos chaines d'acheminements.
            </p>
          </div>
        </div>
    </nav>
    <nav class="flex lg:hidden fixed z-50 top-0 shadow-xl w-full items-center justify-between flex-no-wrap bg-gradient-to-r from-neutral-700 to-primary-500 h-[70px]">
        <div class="flex flex-row" @click="$gtag.event('clickLogo', { method: 'Google' })">
            <img alt="Vue logo" src="../assets/logo.svg"  width="70">
            <img alt="Vue logo" src="../assets/name.svg" width="100">
        </div>
        <div class="flex flex-row w-full justify-end">
            <div class="h-0 w-0 border-t-warning-500 border-l-transparent border-t-[70px] border-l-[120px]"></div>
            <div class="px-5 bg-warning-500 flex flex-row items-center justify-end w-[20vw]">
              <ButtonVue class="!mx-2"
                  :state="'warning'"
                  :height="'medium'"
                  :filling="'text'"
                  :label="'Se connecter'"
                  @click="() => {
                    window.location.replace('https://api.traq-king.com/login?dest=app.traq-king.com')
                    $gtag.event('clickLogin', { method: 'Google' })
                  }"
                  />
            </div>
        </div>
    </nav>


    <div class="flex lg:absolute lg:top-[15vh] lg:right-[5vw] flex-col p-5 rounded-modal bg-gray-50 mt-[80px] w-full md:w-50% lg:w-34% shadow-xl">
      <div class="flex flex-row"
      :class="traqrIdMessage ? 'items-center' : 'items-end'">
          <InputVue
          class="w-full mx-2"
          v-model="traqrId"
          :placeholder="'Numéro de suivi'"
          :height="'large'"
          :state="traqrIdState"
          :message="traqrIdMessage"
          :label="'Entrer un numéro de suivi TraQKING'"
          @update:modelValue="() => {
            isUUID(traqrId) ? traqrIdState = 'default' : traqrIdState = 'warning'
            isUUID(traqrId) ? traqrIdMessage = '' : traqrIdMessage = 'Format non valide'
            !traqrId ? traqrIdState = 'default' : null
            !traqrId ? traqrIdMessage = '' : null
            $gtag.event('inputTrackingNumber', { method: 'Google' })
            }"
          @keyup.enter="() => {
              findTraqr()
              $gtag.event('clickSearchTrackingNumber', { method: 'Google' })
            }"
          />
          <ButtonVue class="!max-w-[80px] !min-w-[80px] !px-0"
          :label="null"
          :filling="'filled'"
          :size="'large'"
          :icon="'MagnifyingGlassIcon'"
          :state="'default'"
          :disabled="false"
          @click="() => {
              findTraqr()
              $gtag.event('clickSearchTrackingNumber', { method: 'Google' })
            }"
          />
      </div>
      <div v-if="traqr" class="flex flex-col justify-center items-center w-full">
          <div v-if="!loading" class="w-full flex flex-row justify-center items-center">
              <div class="flex flex-col">
                  <span v-if="traqr.state == 'delivered'" class="text-title-2 text-gray-600 mt-4 ml-2 mb-2">
                      Livré
                  </span>
                  <span v-else-if="traqr.state == 'transit'" class="text-title-2 text-gray-600 mt-4 ml-2 mb-2">
                      En transit
                  </span>
                  <span v-if="traqr.state == 'awaiting'" class="text-title-2 text-gray-600 mt-4 ml-2 mb-2">
                      En attente de prise en charge
                  </span>
                  <div class="h-full flex flex-row justify-start items-center my-2">
                      <div class="flex items-center justify-center">
                          <CountryFlag :country='traqr.consignedTo.address.getCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
                      </div>
                      <div class="flex flex-col justify-center items-start ml-3">
                          <div class="h-full flex flex-col justify-center items-start">
                              <span class="text-body-1 text-gray-600">
                                Destination
                              </span>
                              <span class="text-body-2 text-neutral-700">{{ traqr.consignedTo.address.getAddress() }}, {{ traqr.consignedTo.address.getCity() }}</span>
                          </div>
                      </div>
                  </div>
                  <div class="flex flex-col items-start justify-start pl-4 overflow-y-scroll">
                      <div v-for="(scan, index) in traqr.scans" :key="index">
                          <TraqrScan v-if="scan.address !== null" :scan="scan"/>
                      </div>
                  </div>
              </div>
          </div>
          <div v-else class="flex flex-col h-full justify-center items-center w-full my-10">
              <LoadingVue/>
          </div>
      </div>
  </div>
  <div class="flex lg:hidden mt-6 left-[2%] h-[25%] flex-col justify-start items-start p-4">
        <h1 class="text-title-3 text-gray-700 mb-10">Apportez de la visibilité à votre supply chain au travers de TraQKING, un outil leger et ergonomique pour tous les acteurs de vos acheminements !</h1>
        <p class="mb-4 text-justify text-gray-500">
          TraQKING propose un procédé simple en trois étapes permettant de récuperer l'information nécessaire pour une meilleure visibilité de votre Supply Chain. 
        </p>
        <p class="mb-4 text-justify text-gray-500">
          Que vous soyez <b>chargeur</b>, <b>affréteur</b>, ou bien <b>transporteur</b>, vous pourrez centraliser toutes les informations de vos expéditions au sein d'un espace dédié qui deviendra le seul intermédiaire entre tous les acteurs de vos chaines d'acheminements.
        </p>
      </div>

  </template>
  
  <script>
  import InputVue from './elements/Input.vue'
  import ButtonVue from './elements/Button.vue'
  import LoadingVue from './elements/Loading.vue'
  import TraqrScan from './groups/TraqrScan.vue'
  import CountryFlag from 'vue-country-flag-next'

  import Traqr from "@/lib/entities/Traqr"

  export default {
    name: 'HeaderVue',
    components: {
      ButtonVue,
      InputVue,
      LoadingVue,
      CountryFlag,
      TraqrScan
    },
    data () {
        return {
          view: {
              topOfPage: true
          },
          window: null,
          traqrId: null,
          traqr: null,
          traqrIdState: 'default',
          traqrIdMessage: null,
          loading: true
        }
    },
    beforeMount() {
      this.window = window;
      this.window.addEventListener('scroll', this.handleScroll)

      this.findGetParameter('id') ? this.traqrId = this.findGetParameter('id') : null;
      this.traqrId ? this.findTraqr() : null

    },
    methods: {
        log(e){console.log(e)},
        handleScroll(){
          if(window.pageYOffset>0){
              if(this.view.topOfPage) this.view.topOfPage = false
          } else {
              if(!this.view.topOfPage) this.view.topOfPage = true
          }
        },

        async findTraqr(){
            this.loading = true
            if(!this.isUUID(this.traqrId)){
              this.traqrIdState = 'danger';
              this.traqrIdMessage = 'Numéro de suivi non valide';
              this.$gtag.event('searchTrackingNumberError', { method: 'Google' })
              return;
            }
            this.traqr = new Traqr({id : this.traqrId})
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/traqrs/public/'+this.traqrId)
            .then(response => {
                if(response.status == 200){
                    this.traqrIdState = 'success';
                    this.traqrIdMessage = null;
                    this.traqr = new Traqr(response.data)
                    this.loading = false
                    const url = new URL(window.location.href);
                    url.searchParams.set('id', this.traqr.id);
                    window.history.replaceState(null, null, url);
                }
                this.$gtag.event('searchTrackingNumberSuccess', { method: 'Google' })
            }).catch(() => {
                this.traqr = null;
                this.loading = false
                this.traqrIdState = 'danger';
                this.traqrIdMessage = 'Numéro de suivi non trouvé';
                this.$gtag.event('searchTrackingNumberError', { method: 'Google' })
            })
        },

        findGetParameter(parameterName) {
          var result = null,
              tmp = [];
          location.search
              .substr(1)
              .split("&")
              .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
              });
          return result;
      },
      isUUID ( uuid ) {
          let s = "" + uuid;
          s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
          if (s === null) {
            return false;
          }
          return true;
      }
    },
  }
  </script>

<style scoped>
  * {
  padding: 0;
  margin: 0;
}

#fixed-nav {
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}
#fixed-nav.onScroll {
  box-shadow: 0 0 10px #000;
  background: linear-gradient(to right, rgb(28, 51, 69), rgb(70, 119, 180)) !important;
}

.triangle-main {
  width: 450px;
  height: 450px;
  position: relative;
}

.triangle-bottom{
  width: 450px;
  height: 450px;
  background: transparent;
}

.triangle-bottom {
  height: 450px;
  float: right;
  bottom: 0;
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
  shape-outside: polygon(0% 100%, 100% 100%, 100% 0%);
}

</style>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  