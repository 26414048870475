<template>
    <div id="activity" class="pb-[80px] flex flex-col bg-gray-50">
        <div class="hidden lg:flex justify-center bg-gray-500 pt-[100px]">
            <img src="../assets/chaine.png" alt="Chaine avec TraQKING" class="lg:w-[66%]">
        </div>
        <div class="flex lg:hidden justify-center bg-gray-500 pt-[100px]">
            <img src="../assets/chaine-vertical.png" alt="Chaine avec TraQKING" class="w-[90%]">
        </div>
        <div class="h-0 w-0 border-t-gray-500 border-l-transparent border-t-[100px] border-l-[100vw] lg:border-l-[80vw]"></div>
        <div class="pt-[100px] lg:pt-0 flex flex-col lg:flex-row justify-between items-end lg:items-center ">
            <div class="flex flex-row justify-around items-center lg:ml-[20%]">
                <img class="w-[50px]" alt="Puce Gauche" src="../assets/puce-gauche.png">   
                <p class="w-[66%] lg:w-[50%] text-justify text-neutral-800">
                    Éditez vos TraQRs (bons d'expéditions) ainsi que les étiquettes pour vos unités de manutention au travers de notre plateforme. 
                </p>
            </div> 
            <img class="max-h-[50vh] max-w-[50vw] shadow-lg -rotate-12 mt-10 lg:mt-0" alt="TraQKING Bon" src="../assets/traqking-bon.png"> 
        </div>
        <div class="h-0 w-0 border-b-gray-500 border-r-transparent border-b-[100px] border-r-[100vw] lg:border-r-[80vw]"></div>
        <div class="flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center bg-gray-500">
            <img class="max-h-[50vh] max-w-[50%] rotate-12 mt-10 lg:mt-0" alt="TraQKING Scan" src="../assets/traqking-scan.png"> 
            <div class="flex flex-row justify-around items-center lg:mr-[20%]">
                <p class="w-[66%] lg:w-[50%] text-justify text-gray-50">
                    Les QR codes apposés permettent, via un simple scan à chaque étape de la chaine d'acheminement, d'actualiser la localisation de l'expédition. L'opération se fait <b>sans</b> avoir recours à une application, et sans compte utilisateur. 
                    L'étape peut ensuite être qualifiée par le chauffeur grâce à différents statuts et un utilitaire pour téleverser facilement des documents physiques.
                </p>
                <img class="w-[50px]" alt="Puce Droite" src="../assets/puce-droite.png">   
            </div>  
        </div>
        <div class="h-0 w-0 border-t-gray-500 border-r-transparent border-t-[100px] border-r-[100vw] lg:border-r-[80vw]"></div>
        <div class="flex flex-col lg:flex-row justify-between mt-10 lg:mt-0 items-end lg:items-center">
            <div class="flex flex-row justify-around items-center lg:ml-[20%]">
                <img class="w-[50px]" alt="Puce Gauche" src="../assets/puce-gauche.png">   
                <p class="w-[66%] lg:w-[50%] text-justify text-neutral-800">
                    L'ensemble des informations de suivi est disponible au travers d'un panneau de contrôle en ligne ou via votre TMS. Les dossiers sont classés intelligement et des filtres sont à votre disposition pour trouver l'information souhaitée en quelques clics ! 
                </p>
            </div> 
            <img class="w-[40vw] min-w-[400px] translate-x-[20%]" alt="Dashboard Screen" src="../assets/screen-dashboard.png">  
        </div>
    </div>
</template>
  
<script>
export default {
name: 'ActivityVue',
}
</script>

  
  