<template>
    <div class="flex flex-col justify-between items-center w-[100%] bg-gray-50 py-[80px]">
        <div class="w-[66%] border border-gray-400"></div>
        <h2 class="text-neutral-700 text-title-2  p-10 w-[100%] flex flex-col justify-center items-center">
            Ils nous accompagnent
        </h2>
        <div class="w-[66%] border border-gray-400 mb-10"></div>
        <div class="flex flex-row flex-wrap w-[66%] justify-between items-center mb-10">
            <a href="https://em-lyon.com/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="emlyon Business School Logo" src="../assets/logo-emlyon.png"></a>
            <a href="https://www.bpifrance.fr/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="BPI France Logo" src="../assets/logo-bpifrance.png"></a>
            <a href="https://www.lafrenchtech-stl.com/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="La French Tech Logo" src="../assets/logo-frenchtech.png"></a>
            <a href="https://www.hexatrans.com/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="HEXATrans Logo" src="../assets/logo-hexatrans.png"></a>
            <a href="https://www.mines-stetienne.fr/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="Ecole des Mines de Saint Etienne Logo" src="../assets/logo-emse.png"></a>
            <a href="https://pepiteprovence.fr/"><img class="max-h-[80px] max-w-[80px] lg:max-h-[120px] lg:max-w-[120px] border-[5px] m-2" alt="Pepite Provence Logo" src="../assets/logo-pepite.png"></a>
        </div>
    </div>
</template>
  
<script>
export default {
name: 'ReferenceVue',
}
</script>