<template lang="html">
    <div class="flex flex-row justify-start items-start h-[10rem] border-0 w-full">
        <div class="h-full w-0 border-l-2 py-2"></div>
        <div class="h-50% w-5 border-b-2"></div>
        <div class="h-full flex justify-between items-start w-full py-2">
          <div class="h-full flex flex-row justify-center items-center">
              <div class="flex items-center justify-center" v-if="scan.getCountry()">
                  <CountryFlag :country='scan.getCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
              </div>
              <div class="flex flex-col justify-center items-start ml-3">
                <div class="h-full flex flex-col justify-center items-start">
                    <span v-if="scan.hasAddress()" class="text-body-2 text-neutral-700">{{ scan.getAddress() ? scan.getAddress()+", " : "" }}{{ scan.getCity() }}</span>
                    <span class="text-body-2 text-gray-500">{{ scan.getLastScanTime() }} {{ scan.getLastScanDate() }}</span>
                </div>
                <div class="h-full flex flex-row justify-center items-center">
                    <span class="text-body-2 text-gray-500">{{ scan.getScannedEntity() }}</span>
                </div>
              </div>
          </div>
        </div>
    </div>
  </template>
  
  <script lang="js">
  
    import CountryFlag from 'vue-country-flag-next'
    import Scan from '@/lib/entities/Scan'
  
    export default  {
      name: 'TraqrScan',
      components:{
        CountryFlag,
      },
      props: {
        scan: {
          type: Scan,
        },
      },
      watch: {
        scan: {
          handler() {},
          deep: true
        },
      },
  }
  
  
  </script>
  