<template>
    <div class="w-[100%] bg-gray-500 ">
        <div class="flex flex-col lg:flex-row justify-start lg:justify-around w-[80vw] text-gray-100 text-body-3 py-6 px-2">
                <div class="flex flex-col">
                    <span>© 2024 G2GO Solutions.</span>
                    <span>Tous droits réservés.</span>
                </div>
                <span>CONDITIONS GÉNÉRALES</span>
                <span>POLITIQUE DE CONFIDENTIALITÉ</span>
                <span>MENTIONS LÉGALES</span>
            </div>
    </div>
</template>
  
<script>
export default {
name: 'FooterVue',
}
</script>