<template>
    <div id="contact" class="py-[80px] w-[100%] flex flex-col lg:flex-row bg-gradient-to-r from-neutral-700 to-primary-500">
        <div class="w-[100%] md:w-[50%] lg:w-[66%] flex flex-col justify-center items-center">
            <h2 class="text-title-3 text-white">
                Contactez nous !
            </h2>
        </div>
        <div class="w-[100%] md:w-[50%] lg:w-[34%] flex flex-col p-[2rem]">
            <div class="w-[100%] flex flex-row justify-between mb-2">
                <InputVue class="w-[50%] mr-4"
                v-model="firstName"
                :placeholder="'Prénom'"
                :height="'large'"
                :state="'default'"
                />
                <InputVue class="w-[50%]"
                v-model="lastName"
                :placeholder="'Nom'"
                :height="'large'"
                :state="'default'"
                />
            </div>
            <InputVue class="w-[100%] my-2"
            v-model="company"
            :placeholder="'Entreprise'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="w-[100%] my-2"
            v-model="jobTitle"
            :placeholder="'Intitulé de votre poste'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="w-[100%] my-2"
            v-model="email"
            :placeholder="'Email'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="w-[100%] my-2"
            v-model="phoneNumber"
            :placeholder="'N° de Téléphone'"
            :height="'large'"
            :state="'default'"
            />
            <TextVue class="w-[100%] my-2"
            v-model="description"
            :placeholder="'Description de votre demande'"
            :height="'large'"
            :state="'default'"
            />
            <ButtonVue
            :state="'warning'"
            :height="'large'"
            :filling="'filled'"
            :label="'Envoyer votre demande'"
            @click="sendRecontactRequest()"
            />
        </div>
        <ModalVue v-show="modalContent.show"
        :title="modalContent.title"
        :confirmButtonLabel="'OK'"
        @closeModal="modalContent.show = false"
        @confirm="modalContent.show = false">
            <template v-slot:body>
                    <p>{{ modalContent.message }}</p>
            </template>
        </ModalVue>
    </div>
</template>
  
<script>
import InputVue from "./elements/Input.vue"
import TextVue from "./elements/Text.vue"
import ButtonVue from "./elements/Button.vue"
import ModalVue from "./containers/Modal.vue";

export default {
    name: 'ContactVue',
    components: {
        InputVue,
        TextVue,
        ButtonVue,
        ModalVue
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            company: null,
            jobTitle: null,
            email: null,
            phoneNumber: null,
            description: null,
            modalContent : {
                show: false,
                title : "",
                message : "",
                type: "",
            },
        }
    },
    methods: {
        async sendRecontactRequest(){
            await this.$axios
            .post(this.$basePath +'/api/contact_form',
            {
                firstName: this.firstName,
                lastName:this.lastName,
                email:  this.email,
                phone: this.phoneNumber,
                content: this.description,
                company: this.company,
                role: this.jobTitle,
                subject:'Contact via Landing Page',
            }, 
            { 
                withCredentials: true 
            }).then(() => {
                this.modalContent.show = true
                this.modalContent.message = 'Votre demande a bien été enregistrée, vous serez recontacté dans les plus brefs délais...'
                this.modalContent.type = 'success'
                this.modalContent.to = '/'
                this.modalContent.title = '🟢 Confirmation' 
            }).catch(() => {
                this.modalContent.show = true
                this.modalContent.message = 'Une erreur est survenue, veuillez réessayer plus tard. Nous vous prions d\'accepter nos excuses pour la gène occasionnée...'
                this.modalContent.type = 'danger'
                this.modalContent.to = '/'
                this.modalContent.title = '🔴 Erreur'
            });
        },
    }
    }
</script>
